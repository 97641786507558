import codigosPostales from '../../constants/cp'

const CP_REGEX = /^(AD|[0-9]{2})[0-9]{3}$/
const CP_REGEX_PT = /^[0-9]{4}-[0-9]{3}$/

export const ValidateCP = function (cp) {
  if (process.env.GATSBY_WEB === 'rodimotor' && cp.match(CP_REGEX_PT)) {
    return true
  } else if (process.env.GATSBY_WEB !== 'rodimotor' && cp.match(CP_REGEX)) {
    const provinciaCP = codigosPostales.find(codigoPostal =>
      cp.startsWith(codigoPostal.cp)
    )
    if (provinciaCP) return true
  }
  return false
}
